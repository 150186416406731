import request from '@/utils/request';

const PATH = '/stay';

export const getStayList = (params: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...params,
    page: params.page - 1,
  },
});

export const getStay = (idx: any, params: any) => request({
  url: `${PATH}/${idx}`,
  method: 'get',
  params,
});

export const getMainStayList = (params: any) => request({
  url: `${PATH}/main/list`,
  method: 'get',
  params,
});

export const getSearchStayList = (params: any) => request({
  url: `${PATH}/search/list`,
  method: 'get',
  params,
});

export const getMainAiStayList = (params: any) => request({
  url: `${PATH}/main/ai/list`,
  method: 'get',
  params,
});

export const getMapStayList = (params: any) => request({
  url: `${PATH}/map/list`,
  method: 'get',
  params,
});
