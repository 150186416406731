import request from '@/utils/request';

const PATH = '/event/post';

export const getEventPostList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getMainEventPostList = () => request({
  url: `${PATH}/main/list`,
  method: 'get',
});

export const getEventPost = (idx: any) => request({
  url: `${PATH}/${idx}`,
  method: 'get',
});

export const getEventApply = (idx: any) => request({
  url: `${PATH}/apply/${idx}`,
  method: 'post',
});
