
import moment from 'moment';
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getMainStayList } from '@/api/stay';
import { addFavoriteItem, deleteFavoriteItem } from '@/api/favoriteItem';
import { UserModule } from '@/store/user';
import { getMainStoreInfoList } from '@/api/storeInfo';
import { getMainEventPostList } from '@/api/eventPost';
import { getBannerList } from '@/api/banner';
import { getIndustryList } from '@/api/industry';
import { getOptionItemList } from '@/api/optionItem';
// import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import Calendar from '@/components/Calendar/index.vue';
import $ from 'jquery';
import Slick from 'vue-slick';

import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import { getRecentList } from '@/utils/localStorage';
import AiSearchList from './components/aiSearchList.vue';
import { LatLng } from '../latlng';

@Component({
  components: {
    Calendar,
    AiSearchList,
    Slick,
  },
})

export default class extends Vue {
  mounted() {
    this.init();
    this.getMainEventList();
    this.getBannerList();
    this.getRegionList();
    this.getIndustryList();
    this.slotVisibleDefault = true;
    this.slotVisibleAi = false;
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', this.handleSearch);
    window.addEventListener('scroll', this.handleFix);
    /* eslint-disable */
    /* if (window.innerWidth > 700) {
      if(this.getCookie("popup")!="true"){
      var option = "width = 500, height = 700, top = 100, left = 200, location = no"
      var openWindow = window.open('', '_blank', option);
      let str = '<style>body{margin:0px;}</style><a onclick="javascript:window.close();"><img src ="/api/attached-file/fffacec3-380a-46a8-b7af-f8c4bd450b2a" width = 500, height = 700></a><div style="position: absolute;right: 10px;"><input id="chk" type="checkbox" onclick="javascript:window.opener.setCookie(\'popup\',\'true\',1);window.close();"><label for="chk">오늘 하루 보지않기</label></input></div>';
         openWindow?.document.write(str);
      }
    } else {
    } */
    /* eslint-enable */
    setTimeout(() => {
      this.loadingClass = true;
    }, 2500);
    // 최근 목록
    this.recentList = getRecentList();
  }

  @Watch('dialogStatus')
  private dialogClose() {
    if (this.dialogStatus === false) {
      this.dialogClose();
    }
  }

  /* eslint-disable */
  private getCookie(name: any) {
    var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value? value[2] : null;
  };
  /* eslint-enable */

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleSearch);
    window.removeEventListener('scroll', this.handleFix);
  }

  @Prop({ required: true }) private dialogStatus!: Boolean;

  get currentColor(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 7 && hour < 18) {
      return 'color07';
    }
    if (hour >= 18 && hour < 20) {
      return 'color18';
    }
    if (hour >= 20 && hour < 24) {
      return 'color20';
    }
    if (hour >= 0 && hour < 4) {
      return 'color20';
    }
    if (hour >= 4 && hour < 7) {
      return 'color4';
    }
    return '';
  }

  private activeMenu = false;

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private totalSearchQuery: any = {
    searchValue: '',
    industryIdx: null,
    // startDate: moment().format('YYYY-MM-DD'),
    // endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    startDate: '',
    endDate: '',
    adult: 0,
    child: 0,
  }

  private loadingClass = false;

  private mainLoading = true;

  private dialogTitle = '';

  private favoriteStatus = '';

  private loading = true;

  private searchInput = '';

  private recentList: any = [];

  private isMarked= false;

  private isScrolled= false;

  private isSearch= false;

  private isFixed= false;

  private centerDialogVisible= false;

  private dialogNotifyVisible = false;

  private isClickActive = false;

  private slotVisible= false;

  private regionList = [];

  private stayList = [];

  private eventList = [];

  private bannerList = [];

  private pageSize = (window as any).innerWidth;

  private searchClose1 = true;

  private searchClose2 = true;

  private mainItem = {
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
    productList: [],
  }

  private mainBannerOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    dots: true,
    dotsClass: 'slick-dots banner__slide-dots',
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  private eventOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    dots: true,
    dotsClass: 'slick-dots event__slide-dots',
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  private listQuery: any = {
    page: 0,
    size: 8,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    lat: '37.566826',
    lng: '126.9786567',
    sort: 'distance',
    region: '',
    adult: 2,
    child: 0,
    startPrice: 0,
    endPrice: 1000000,
    categories: '',
    options: '',
  }

  private industryList = [];

  private dateRange = [
    moment().format('YYYY-MM-DD'),
    moment().add(1, 'days').format('YYYY-MM-DD'),
  ];

  private focusInput() {
    this.dialog = true;
    this.$nextTick(() => {
      const inputElement = (this.$refs.searchInput as any).$el.querySelector('input');
      inputElement.focus();
      document.documentElement.classList.add('noscr');
    });
  }

  private getRandomLatLng(): LatLng {
    const lat = this.getRandomInRange(34.88445926852321, 34.88957198305561, 6);
    const lng = this.getRandomInRange(127.8174539194058, 127.89031601550143, 6);
    return { lat, lng };
  }

  private getRandomInRange(min: number, max: number, decimalPlaces: number): number {
    const randomValue = Math.random() * (max - min) + min;
    return parseFloat(randomValue.toFixed(decimalPlaces));
  }

  private init() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.listQuery.lat = lat;
      this.listQuery.lng = lng;
      this.totalSearchQuery.lat = lat;
      this.totalSearchQuery.lng = lng;
      this.getMainStayList();
      this.getMainStoreInfoList();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.listQuery = {
            ...this.listQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.totalSearchQuery = {
            ...this.totalSearchQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          this.getMainStayList();
          this.getMainStoreInfoList();
        },
        (error) => {
          const randomLocation = this.getRandomLatLng();
          const randomLat = randomLocation.lat;
          const randomLng = randomLocation.lng;
          this.listQuery = {
            ...this.listQuery,
            lat: randomLat,
            lng: randomLng,
          };
          this.getMainStayList();
          this.getMainStoreInfoList();
        },
      );
    }
  }

  private goRecent(link: string) {
    this.$router.push(link);
  }

  private handleResize() {
    this.pageSize = (window as any).innerWidth;
  }

  private selectedPersonnel() {
    this.slotVisible = !this.slotVisible;
  }

  private getMainStayList() {
    getMainStayList(this.listQuery).then((res) => {
      this.stayList = res.data;
      this.mainLoading = false;
    });
  }

  private handleViewStay(idx: any) {
    this.$router.push({
      name: 'StayDetail',
      params: { stayIdx: idx },
      query: {
        startDate: this.listQuery.startDate,
        endDate: this.listQuery.endDate,
        adult: this.listQuery.adult,
        child: this.listQuery.child,
      },
    });
  }

  private handleTotalSearch() {
    this.$router.push({ name: 'TotalSearch', query: { ...this.totalSearchQuery } });
    document.documentElement.classList.remove('noscr');
  }

  private favoriteFlag = false;

  private async handleAddFavoriteStay(event: Event, idx: any, type: number) {
    if (this.favoriteFlag) {
      this.$message.info('저장중입니다.');
      return;
    }
    this.favoriteFlag = true;
    if (UserModule.token) {
      const target = (event.target as HTMLElement)?.parentElement?.parentElement?.parentElement?.parentElement;
      if (target?.classList.contains('active')) {
        if (target.getAttribute('data-id')) {
          await deleteFavoriteItem(target.getAttribute('data-id')).then(() => {
            this.$message.success('삭제되었습니다.');
            target.classList.remove('active');
            target.removeAttribute('data-id');
          });
        } else {
          this.$message.info('해당 서비스를 찾을 수 없습니다.');
        }
      } else if (target && !(target?.classList.contains('active'))) {
        let query = {};
        if (type === 1) query = { stayIdx: idx };
        else if (type === 2) query = { storeInfoIdx: idx };
        await addFavoriteItem(query).then((res) => {
          this.$message.success('저장되었습니다.');
          target.classList.add('active');
          target.setAttribute('data-id', res.data);
        });
      }
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
    this.favoriteFlag = false;
  }

  private getMainStoreInfoList() {
    getMainStoreInfoList({
      page: 0,
      size: 6,
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    }).then((res) => {
      this.mainItem = {
        ...this.mainItem,
        ...res.data,
      };
    });
  }

  private getMainEventList() {
    getMainEventPostList().then((res) => {
      this.eventList = res.data;
    });
  }

  private getBannerList() {
    getBannerList().then((res) => {
      this.bannerList = res.data;
      // 최상단으로 이동
      window.scrollTo(0, 0);
    });
  }

  private getPickerOptions() {
    const options = {
      disabledDate(time: any) {
        return moment(time).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
      },
    };
    return options;
  }

  private getIndustryList() {
    getIndustryList().then((res) => {
      this.industryList = res.data;
      this.industryList.forEach((item: any, index: any) => {
        if (item.name === '일반상품') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '테스트') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '레저') {
          this.industryList.splice(index, 1);
        }
        if (item.name === '체험') {
          item.name = '레저 / 체험';
        }
      });
    });
  }

  private getRegionList() {
    getOptionItemList('REGION').then((res) => {
      this.regionList = res.data;
    });
  }

  private slotVisibleDefault = true;

  private slotVisibleAi= false;

  private isSeclect01Visible = false;

  private isSeclect02Visible = false;

  private isSeclect03Visible = false;

  private isSeclect04Visible = false;

  private openSeclect01() {
    this.isSeclect01Visible = !this.isSeclect01Visible;
    this.isSeclect02Visible = false;
    this.isSeclect03Visible = false;
    this.isSeclect04Visible = false;
  }

  private openSeclect02() {
    this.isSeclect01Visible = false;
    this.isSeclect03Visible = false;
    this.isSeclect04Visible = false;
    this.isSeclect02Visible = !this.isSeclect02Visible;
  }

  private openSeclect03() {
    this.isSeclect01Visible = false;
    this.isSeclect02Visible = false;
    this.isSeclect04Visible = false;
    this.isSeclect03Visible = !this.isSeclect03Visible;
  }

  private openSeclect04() {
    this.isSeclect01Visible = false;
    this.isSeclect02Visible = false;
    this.isSeclect03Visible = false;
    this.isSeclect04Visible = !this.isSeclect04Visible;
  }

  private closeModal() {
    this.isSeclect01Visible = false;
    this.isSeclect02Visible = false;
    this.isSeclect03Visible = false;
    this.isSeclect04Visible = false;
  }

  private handleScroll() {
    if (window.scrollY > 700) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  private handleSearch() {
    const windowHeight = window.innerHeight;
    const scrollPosition = window.scrollY;
    const scrollPercent = (scrollPosition / windowHeight) * 100;

    // 여기에서 적절한 퍼센트 값을 설정합니다.
    if (scrollPercent > 36) { // 예시: 스크롤 위치가 화면의 절반 이상인 경우
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }
  }

  private handleFix() {
    if (window.scrollY > 4200) {
      this.isSearch = false;
      this.isScrolled = false;
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  private truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  }

  private truncateName(name: string, maxLength: number) :string {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`;
    }
    return name;
  }

  private handleSearchDialogVisible() {
    this.dialogTitle = '통합검색';
    this.centerDialogVisible = !this.centerDialogVisible;
    this.slotVisibleAi = false;
    if (!this.slotVisibleDefault) this.slotVisibleDefault = true;
    // 모달 닫을 때 <html> 스크롤 되도록
    document.documentElement.classList.remove('noscr');
  }

  private handleDefaultSearch() {
    this.slotVisibleDefault = true;
    this.slotVisibleAi = false;
    this.isClickActive = false;
  }

  private handleAisearch() {
    this.dialogTitle = '낭만\'s PICK';
    if (!this.centerDialogVisible) this.centerDialogVisible = true;
    this.slotVisibleDefault = false;
    this.slotVisibleAi = true;
    this.isClickActive = !this.isClickActive;
  }

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.totalSearchQuery.startDate = startDate;
    this.totalSearchQuery.endDate = endDate;
  }

  private handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.handleTotalSearch();
    }
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }

  private handleResetFormQuery() {
    this.totalSearchQuery = {
      searchValue: '',
      industryIdx: null,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      adult: 0,
      child: 0,
      page: 0,
      size: 4,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }

  private stayNavigateToDetail(stayIdx: string) {
    this.$router.push({ name: 'StayDetail', params: { stayIdx } });
  }

  private restaurantNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'RestaurantView', params: { idx: storeIdx } });
  }

  private hotplaceNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'PlaceView', params: { idx: storeIdx } });
  }

  private leisureNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'LeisureView', params: { idx: storeIdx } });
  }

  private experiencNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'ExcitingView', params: { idx: storeIdx } });
  }

  private accomodationOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.accomodation__prev'),
    nextArrow: $('.accomodation__next'),
    speed: 400,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 2,
          autoplay: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          rows: 2,
          autoplay: false,
        },
      },
    ],
  };

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private restaurantOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.restaurant__prev'),
    nextArrow: $('.restaurant__next'),
    speed: 400,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          rows: 1,
          autoplaySpeed: 10000,
          speed: 600,
          autoplay: true,
        },
      },
    ],
  };

  private handleClickRestaurantSlide(str: string) {
    const slick = (this.$refs.restaurantBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private hotplaceOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.hotplace__prev'),
    nextArrow: $('.hotplace__next'),
    speed: 400,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          rows: 1,
          autoplaySpeed: 10000,
          speed: 600,
          autoplay: true,
        },
      },
    ],
  };

  private handleClickHotplaceSlide(str: string) {
    const slick = (this.$refs.hotplaceBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private leisureOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.leisure__prev'),
    nextArrow: $('.leisure__next'),
    speed: 400,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          rows: 1,
          autoplaySpeed: 10000,
          speed: 600,
          autoplay: true,
        },
      },
    ],
  };

  private handleClickLeisureSlide(str: string) {
    const slick = (this.$refs.leisureBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private experimentOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.experiment__prev'),
    nextArrow: $('.experiment__next'),
    speed: 400,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          rows: 1,
          autoplaySpeed: 10000,
          speed: 600,
          autoplay: true,
        },
      },
    ],
  };

  private handleClickExperimentSlide(str: string) {
    const slick = (this.$refs.experimentBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private dialog = false;

  private closeAiDialog() {
    this.dialog = true;
  }

  private handleCloseDialog(isClose: boolean) {
    console.log('isClose'); // true
    console.log(isClose); // true
    // 추가적인 로직 구현
  }
}
