
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getMainAiStayList } from '@/api/stay';
import { UserModule } from '@/store/user';
import { addFavoriteItem, deleteFavoriteItem } from '@/api/favoriteItem';
import { getAiStoreInfoList } from '@/api/storeInfo';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';

@Component({
  name: 'aiSearchList',
})
export default class extends Vue {
  mounted() {
    this.getAiItemList();
  }

  private favoriteFlag = false;

  private async handleAddFavoriteStay(event: Event, idx: any, type: number) {
    if (this.favoriteFlag) {
      this.$message.info('저장중입니다.');
      return;
    }
    this.favoriteFlag = true;
    if (UserModule.token) {
      const target = (event.target as HTMLElement)?.parentElement?.parentElement?.parentElement?.parentElement;
      if (target?.classList.contains('active')) {
        if (target.getAttribute('data-id')) {
          await deleteFavoriteItem(target.getAttribute('data-id')).then(() => {
            this.$message.success('삭제되었습니다.');
            target.classList.remove('active');
            target.removeAttribute('data-id');
          });
        } else {
          this.$message.info('해당 서비스를 찾을 수 없습니다.');
        }
      } else if (target && !(target?.classList.contains('active'))) {
        let query = {};
        if (type === 1) query = { stayIdx: idx };
        else if (type === 2) query = { storeInfoIdx: idx };
        await addFavoriteItem(query).then((res) => {
          this.$message.success('저장되었습니다.');
          target.classList.add('active');
          target.setAttribute('data-id', res.data);
        });
      }
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
    this.favoriteFlag = false;
  }

  private aiSearchLoading = false;

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private stayAiList: any[] = [];

  private aiItem: any = {
    stayList: [],
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
  }

  @Prop({ required: true }) private listQuery!: any;

  private getAiItemList() {
    this.aiSearchLoading = true;
    getMainAiStayList({
      page: 0,
      size: 10,
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    }).then((res) => {
      this.stayAiList = res.data;
      // 중복 제거 후, 4번째 항목까지만 출력
      const map = new Map();
      this.stayAiList.forEach((item: any) => {
        map.set(item.idx, item);
      });
      this.stayAiList = [...map.values()];
      this.stayAiList = this.stayAiList.splice(0, 4);
    });
    getAiStoreInfoList({
      page: 0,
      size: 10,
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    }).then((res) => {
      this.aiItem = {
        ...this.aiItem,
        ...res.data,
      };
      // 중복 제거 후, 4번째 항목까지만 출력
      const map1 = new Map();
      this.aiItem.excitingList.forEach((item: any) => {
        map1.set(item.idx, item);
      });
      this.aiItem.excitingList = [...map1.values()];
      this.aiItem.excitingList = this.aiItem.excitingList.splice(0, 4);
      // 중복 제거 후, 4번째 항목까지만 출력
      const map2 = new Map();
      this.aiItem.restaurantList.forEach((item: any) => {
        map2.set(item.idx, item);
      });
      this.aiItem.restaurantList = [...map2.values()];
      this.aiItem.restaurantList = this.aiItem.restaurantList.splice(0, 4);
      // 중복 제거 후, 4번째 항목까지만 출력
      const map3 = new Map();
      this.aiItem.placeList.forEach((item: any) => {
        map3.set(item.idx, item);
      });
      this.aiItem.placeList = [...map3.values()];
      this.aiItem.placeList = this.aiItem.placeList.splice(0, 4);
      // 중복 제거 후, 4번째 항목까지만 출력
      const map4 = new Map();
      this.aiItem.leisureList.forEach((item: any) => {
        map4.set(item.idx, item);
      });
      this.aiItem.leisureList = [...map4.values()];
      this.aiItem.leisureList = this.aiItem.leisureList.splice(0, 4);
      this.aiSearchLoading = false;
    });
  }

  private handleItemClick(idx: any, type: any) {
    if (type === 1) { // 숙박
      this.$router.push({
        name: 'StayDetail',
        params: { stayIdx: idx },
        query: {
          startDate: this.listQuery.startDate,
          endDate: this.listQuery.endDate,
          adult: this.listQuery.adult,
          child: this.listQuery.child,
        },
      });
      document.documentElement.classList.remove('noscr');
    } else if (type === 2) { // 체험
      this.$router.push({ name: 'ExcitingView', params: { idx } });
      document.documentElement.classList.remove('noscr');
    } else if (type === 3) { // 레저
      this.$router.push({ name: 'LeisureView', params: { idx } });
      document.documentElement.classList.remove('noscr');
    } else if (type === 4) { // 외식
      this.$router.push({ name: 'RestaurantView', params: { idx } });
      document.documentElement.classList.remove('noscr');
    } else if (type === 6) { // 관광
      this.$router.push({ name: 'PlaceView', params: { idx } });
      document.documentElement.classList.remove('noscr');
    }
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }
}
