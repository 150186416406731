export const getRecentList = () => {
  if (localStorage.getItem('recentList') === null) return [];
  return JSON.parse(localStorage.getItem('recentList') as string);
};

export const addRecentList = (data: any, url: string) => {
  const recentData = {
    idx: data.idx,
    name: data.name,
    addressCity: data.addressCity,
    distance: data.distance,
    imageUrl: data.images[0],
    avgScore: data.avgScore,
    introduce: data.introduce,
    reviewCount: data.reviewCount,
    industryName: data.industryName,
    industryIdx: data.industryIdx,
    minPrice: data.minPrice,
    maxPrice: data.maxPrice,
    tags: data.tags,
    link: url,
  };
  let recentList = [];

  if (localStorage.getItem('recentList') === null) {
    recentList.unshift(recentData);
    localStorage.setItem('recentList', JSON.stringify(recentList));
  } else {
    recentList = getRecentList();
    recentList = recentList.filter((obj: any) => !(obj.idx === recentData.idx && obj.industryIdx === recentData.industryIdx));
    recentList.unshift(recentData);
    recentList = recentList.slice(0, 10);
    localStorage.setItem('recentList', JSON.stringify(recentList));
  }
};

export const removeRecentList = (idx: number, industryIdx: number) => {
  const recentList = getRecentList();
  const updatedList = recentList.filter((obj: any) => !(obj.idx === idx && obj.industryIdx === industryIdx));
  localStorage.setItem('recentList', JSON.stringify(updatedList));
};
